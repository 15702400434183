export const staticPage = [
  {
    name: "login",
    title: "Midwest Goods Inc. -Sign In",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "403",
    title: "403 Page",
    description: "403 page",
  },
  {
    name: "404",
    title: "404 Page Not Found",
    description: "404 page not found",
  },
  {
    name: "account-settings",
    title: "Midwest Goods Inc. - Account Settings",
    description: "",
  },
  {
    name: "account-success",
    title: "Midwest Goods Inc. - Account Success ",
    description: "",
  },
  {
    name: "account",
    title: "Midwest Goods Inc. - Sign In",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "addresses",
    title: "Midwest Goods Inc. -Addresses ",
    description: "All addresses",
  },
  {
    name: "blog-post",
    title: "Blogs",
    description: "All blogs",
  },
  {
    name: "categories",
    title: "Midwest Goods Inc. - Categories",
    description:
      "wholesale,smoke shop,headshop,dispensary,vape supplies,vape mods,box mods,pod systems,vaporizers,e liquid,e juice,e cig,Midwest goods,eliquid,ejuice,disposable ecig,wholesale e juice,wholesale e liquid,wholesale e cig,Midwest Distribution,atomizers,batteries,mechanical mods,replacement coils,drip tips, glass,pipes,bongs,rolling papers,grinders,scales,containers,e-liquid,e-juice,e-cig,ecigs,e-cigs,vapor,electronic vapors,e cigarettes,ecigarettes,e-cigarettes",
  },
  {
    name: "checkout",
    title: "Midwest Goods Inc. - Shopping Cart",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "compare",
    title: "Compare",
    description: "",
  },
  {
    name: "create-account",
    title: "Midwest Goods Inc. - Create Account",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "create-new-account",
    title: "Midwest Goods Inc. - Create Account",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "create-update-address",
    title: "Midwest Goods Inc. - Update Address",
    description: "",
  },
  {
    name: "customer-login",
    title: "Midwest Goods Inc. - Sign In",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "forget-password",
    title: "Midwest Goods Inc. - Forgot Password",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "gift-certificate",
    title: "Midwest Goods Inc. - Gift Certificate",
    description: "",
  },
  {
    name: "preview-page",
    title: "Preview Page",
    description: "",
  },
  {
    name: "reset-password",
    title: "Midwest Goods Inc. - Reset Password",
    description:
      " Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More ",
  },
  {
    name: "savecart",
    title: "Midwest Goods Inc. - Shopping Cart",
    description:
      "Wholesale Vape, Smoke Shop &amp; Headshop Supplies featuring E liquid, E juice, Vaporizers, Atomizers, Batteries, Vape Mods, Box mods, Coils, Glass Water Pipes, Bongs, Hookah, Rolling Papers and Trays &amp; More",
  },
  {
    name: "scanner",
    title: "Midwest Goods Inc. - Scanner",
    description: "",
  },
  {
    name: "search",
    title: "Midwest Goods Inc. - Search Results",
    description:
      "wholesale,smoke shop,headshop,dispensary,vape supplies,vape mods,box mods,pod systems,vaporizers,e liquid,e juice,e cig,Midwest goods,eliquid,ejuice,disposable ecig,wholesale e juice,wholesale e liquid,wholesale e cig,Midwest Distribution,atomizers,batteries,mechanical mods,replacement coils,drip tips, glass,pipes,bongs,rolling papers,grinders,scales,containers,e-liquid,e-juice,e-cig,ecigs,e-cigs,vapor,electronic vapors,e cigarettes,ecigarettes,e-cigarettes",
  },
  {
    name: "sitemap",
    title: "Midwest Goods Inc. - Sitemap",
    description: "",
  },
  {
    name: "success_subscribe",
    title: "Midwest Goods Inc. - Success Subscribe",
    description: "",
  },
  {
    name: "orders",
    title: "Midwest Goods Inc. - Orders",
    description: "",
  },
  {
    name: "track-orders",
    title: "Midwest Goods Inc. - Track Order",
    description: "",
  },
  {
    name: "available-products",
    title: "Midwest Goods Inc. - Available Products",
    description: "",
  },
  {
    name: "preorder-products",
    title: "Midwest Goods Inc. - Preorder Products",
    deescription: "",
  },
  {
    name: "pending-orders",
    title: "Midwest Goods Inc. - Pending Orders",
    description: "",
  },
  {
    name: "pending-orders-details",
    title: "Midwest Goods Inc. - Pending Orders Details",
    description: "",
  },
  {
    name: "completed-orders",
    title: "Midwest Goods Inc. - Completed Orders",
    description: "",
  },
  {
    name: "completed-orders-details",
    title: "Midwest Goods Inc. - Completed Orders Details",
    description: "",
  },
  {
    name: "returns",
    title: "Midwest Goods Inc. - Your Return Requests",
    description: "",
  },
  {
    name: "messages",
    title: "Midwest Goods Inc. - Inbox",
    description: "",
  },
];
